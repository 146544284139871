export const skills = [
  {
    id: 1,
    name: "HTML5",
    percentage: 90,
  },
  {
    id: 2,
    name: "JavaScript (ES6)",
    percentage: 75,
  },
  {
    id: 3,
    name: "Umbraco",
    percentage: 75,
  },
  {
    id: 4,
    name: "CSS3 / SASS (SCSS Modules)",
    percentage: 90,
  },
  {
    id: 5,
    name: "Git / Github",
    percentage: 85,
  },
  {
    id: 3,
    name: "C#",
    percentage: 65,
  },
  {
    id: 3,
    name: ".NET Core",
    percentage: 65,
  },
  {
    id: 6,
    name: "Adobe Photoshop",
    percentage: 75,
  },
  {
    id: 7,
    name: "SEO",
    percentage: 90,
  },
];
